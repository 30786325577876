import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Sidebar, SidebarProps} from "primereact/sidebar";
import {Link} from "./Link";
// @ts-ignore
import LogoImage from "../images/logo.svg";


export type NavSidebarProps = SidebarProps & {
    children: ReactNode;
};
export function NavSidebar({children, ...props}: NavSidebarProps) {
    return <Component fullscreen {...props} icons={<Logo>
        <Link to={"/#home"}>
            <img src={LogoImage}/>
        </Link>
    </Logo>}>
        {children}
    </Component>;
}

const Component = styled<SidebarProps>(Sidebar)`
  &.p-sidebar {
    background: #000000;
  }
`;

const Logo = styled.div`
  position: absolute;
  top: 1.6em;
  left: 1.5em;    
`;
