import * as React from 'react';
import styled from 'styled-components';
import {Colors, Palette, Media, Fonts, CenterSectionCSS} from "../constants";
// @ts-ignore
import FooterLogoImage from "../images/logo-onyellow.svg";
import {Link} from "./Link";
import {Button} from "primereact/button";
import {LegalLinks, PrimaryLinks, SupportLinks} from "../nav";

type FooterProps = {
    className?: string;
}
type FooterItem = {
    id: string;
    text: string;
    url?: string;
}


export function Footer({className}: FooterProps) {
    return (
        <Container className={className}>
            <Section>
                <Top>
                    <Logo src={FooterLogoImage}/>
                    <div className={'links'}>
                        <NavLinks className={'main'}>{PrimaryLinks.map(({url, title}, i) => <NavLink url={url} title={title} i={i}/>)}</NavLinks>
                        <NavLinks className={'help'}>{SupportLinks.map(({url, title}, i) => <NavLink url={url} title={title} i={i}/>)}</NavLinks>
                    </div>
                </Top>
                <Bottom>
                    <Left>
                        <Copyright>Copyright @ 2022 The Buddy Apps Imperative Ltd</Copyright>
                    </Left>
                    <Right>
                        <TermLinks>{LegalLinks.map(({ id, url, text }: FooterItem) => (
                            <TermLink key={id}>
                                <Url href={url}>
                                    {text}
                                </Url>
                            </TermLink>
                        ))}</TermLinks>
                    </Right>
                </Bottom>
            </Section>
        </Container>
    )
}

function NavLink({title, url, i}: {title: string, url: string, i: number}) {
    return <Link key={i} to={url}>
        <Button>{title}</Button>
    </Link>;
}

const WrapperStyle = `
    display: flex ;
    width: 100% ;
    margin: 0 auto;
`;
const Container = styled.footer`
  width: 100%;
  flex-direction: column;
  background: ${Palette.Yellow.hash};
  display: flex;
  
  @media (${Media.laptop}) {
    .links {
      display: flex;
      flex-direction: row;
      
      .main button {
        font-size: 1rem;      
      }  
    }
  }
`;
const Section = styled.section`
  ${CenterSectionCSS} ;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 35px !important;
  @media (${Media.laptop}) {
    padding: 0 6em !important;
  }
`;
const Logo = styled.img`
  flex: 0 0 auto;
  width: 124px;
  margin: 4.4em 0 3em 0 ;
  @media (${Media.laptop}) {
    margin: 5.1em 0 3em 5px;
  }
`;
const Top = styled.div`
  display: flex;
  border-bottom: none;
  flex-direction: column;
  ${WrapperStyle};
  
  @media (${Media.laptop}) {
    flex-direction: row; 
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${Colors.YellowLighten};
  }
`;
const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  font-size: 1.2rem;
  
  &.main {
    button {
      font-size: 1.3rem;          
    }
  }
    
  @media (${Media.laptop}) {
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding-right: 10rem;
      margin-top: 4em;
      margin-left: 4em;
      padding-top: 1rem;  
  }
`;
const Bottom = styled.div`
  ${WrapperStyle};
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse ;
  padding: 0 0 2em 0 ;
  
  @media (${Media.laptop}) {
      flex-direction: row;
      padding: 2.9em 0 5em 0;
  }
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (${Media.laptop}) {
    width: 50%;
  }
`;
const Right = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start ;
  align-items: center;
  @media (${Media.laptop}) {
    width: 50%;
    justify-content: flex-end ;
  }
`;
const Copyright = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 147.5%;
  color: ${Colors.YellowLighten};
  padding-top: 2em ;
  width: 100%;
  @media (${Media.laptop}) {
    width: unset;
  }
`;
const TermLinks = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
const TermLink = styled.li`
  float: none ;
  padding-bottom: 1em ;
  @media (${Media.laptop}) {
    float: left;
  }
`;
const Url = styled.a`
  font-style: normal;
  font-weight: 400;
  line-height: 147.5%;
  text-decoration: none;
  margin-right: .5em;
  color: ${Colors.White};
  font-size: 14px ;
  padding: 14px 0 ;

  @media (${Media.laptop}) {
    font-size: 14px;
  }
`;
