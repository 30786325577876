import * as React from 'react';
import {Button} from "primereact/button";
import styled from 'styled-components';
import {AppBarHeight, CenterSectionCSS, Media, Palette} from "../constants";
import {Link, LinkProps} from "./Link";
import {atom, useRecoilState, useSetRecoilState} from "recoil";
// @ts-ignore
import LogoImage from "../images/logo.svg";
// @ts-ignore
import LogoImageOnYellow from "../images/logo-onyellow.svg";
// @ts-ignore
import NavMobileCloseImage from "../images/mobile-nav-close.svg";
import {NavSidebar} from "./NavSidebar";

export type AppBarProps = {
    onBkg?: 'yellow' | 'dark';
    className?: string;
}


const Links = [
    {url: '/', title: 'Product'},
    {url: '/about', title: 'About'},
    {url: '/contact', title: 'Contact Us'},
];


const sidebarState = atom({
    key: 'sidebarVisible',
    default: false,
});

function NavButton(props: LinkProps) {
    const {children, ...options} = props;
    const setVisible = useSetRecoilState(sidebarState);
    const onAnchorLinkClick = () => setVisible(false);

    return (
        <Link {...options} onAnchorLinkClick={onAnchorLinkClick}>
            <Button>{children}</Button>
        </Link>
    )
}

export function AppBar(props: AppBarProps) {
    const {className, onBkg} = props;
    const [visible, setVisible] = useRecoilState(sidebarState);
    const navContent = (
        <>
            {
                Links.map((link, key) => (
                    <NavButton key={key} className="NavMenuItem" to={link.url}>
                        {link.title}
                    </NavButton>
                ))
            }
            {/*<NavButton to={'/#home'} className="NavMenuGoToApp">Go to the App</NavButton>*/}
        </>
    );

    const onOpenHandler = () => setVisible(true);
    const onCloseHandler = () => setVisible(false);

    return (
        <Container className={`appBar ${className || ''}`}>
            <Content>
                <Logo>
                    <Link to={"/#home"}>
                        <img className='logo_onDark' src={onBkg === 'yellow' ? LogoImageOnYellow: LogoImage}/>
                    </Link>
                </Logo>

                <DesktopNav>
                    {navContent}
                </DesktopNav>
                <MobileNav>
                    <MobileNavButton onClick={onOpenHandler}>
                        <img src={NavMobileCloseImage}/>
                    </MobileNavButton>
                    <NavSidebar visible={visible} onHide={onCloseHandler} position="right" className="ui-sidebar-sm" fullScreen >
                        <OverlayContent>{navContent}</OverlayContent>
                    </NavSidebar>
                </MobileNav>
            </Content>
        </Container>
    )
}


const Container = styled.header`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: ${AppBarHeight.mobile}em;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${Palette.Default.background};

  @media (${Media.mobile}) {
    background-color: transparent ;
    position: absolute ;
    z-index: 999 ;    
  }
  @media (${Media.laptop}) {
    height: ${AppBarHeight.desktop}em;
  }
   
`;
const Content = styled.div`
  ${CenterSectionCSS};
  display: flex;
  width: 100% ;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  @media (${Media.mobile}) {
    padding: 0 ;
  }
`;
const Logo = styled.div`
  display: flex;
  margin: 0;
  padding-top: 1em;
  padding-left: 1.5em;
`;
const DesktopNav = styled.nav`
  display: none;
  align-items: center;
  @media (${Media.desktop}) {
    display: flex;
    flex-direction: row;
  }
`;
const MobileNav = styled.div`
  @media (${Media.desktop}) { display: none }  
`;
const MobileNavButton = styled.div`
  margin-top: 0.5em;
  margin-right: 0.5em;
  cursor: pointer ;
`;
const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  padding-left: 1em;
  
  .NavMenuItem {
    padding-bottom: 1em;
  }
  .NavMenuGoToApp {
    margin-top: 1em;
  }
`;
