export const PrimaryLinks = [
    {url: '/', title: 'Product'},
    {url: '/about', title: 'About'},
    {url: '/contact', title: 'Contact Us'},
]

export const SupportLinks = [
    {url: 'https://feedback.unprn.com/satisfaction', title: 'Give Feedback'},
    {url: 'https://feedback.unprn.com', title: 'Feature Requests'},
    {url: 'https://feedback.unprn.com/feedback/add', title: 'Report Bug'},
];

export const LegalLinks = [
    /*{id: "dm", text: "Data Management", url: ""},*/
    {id: "pp", text: "Privacy Policy", url: "/privacy"},
    {id: "ts", text: "Terms of Service", url: "/terms"},
];
